import React from "react"
import { string, shape, arrayOf, oneOfType, number } from "prop-types"
import { StyledPartners, PartnersWrapper } from "./style"
import Image from "../Image"
import { Heading4 } from "../_globalSettings/typography"

const Partners = ({ nodes, title }) => {
	return (
		<StyledPartners>
			<Heading4>{title}</Heading4>
			<PartnersWrapper>
				{nodes.map(partner => (
					<div className="img_wrapper">
						{partner.link ? (
							<a href={partner.link} target="_blank" rel="noreferrer">
								<Image
									className="partners__image"
									data={partner.image}
									alt={partner.alt}
									key={partner.id}
									width={partner.width}
								/>
							</a>
						) : (
							<Image
								className="partners__image"
								data={partner.image}
								alt={partner.alt}
								key={partner.id}
								width={partner.width}
							/>
						)}
						{partner.chipLink && (
							<div className="logo_reference">
								<a href={partner.chipLink} target="_blank" rel="noreferrer">
									{partner.chipTitle}
								</a>
							</div>
						)}
					</div>
				))}
			</PartnersWrapper>
		</StyledPartners>
	)
}

export const PartnersProps = {
	nodes: arrayOf(
		shape({
			image: oneOfType([
				string,
				shape({
					childImageSharp: shape({
						fluid: shape({
							aspectRatio: number,
							sizes: string,
							src: string,
							srcSet: string,
							// srcSetWebp: string,
							// srcWebp: string,
						}).isRequired,
					}),
				}),
			]).isRequired,
			alt: string.isRequired,
		})
	),
}

Partners.propTypes = {
	...PartnersProps,
}

export default Partners
