import styled from "styled-components"
import { breakpoint } from "../_globalSettings/var"

const StyledPartners = styled.div`
	width: 100%;
	padding: 122px 0;
	display: flex;
	align-items: center;
	.partners__image {
		max-width: 248px;
	}
	h4 {
		margin-right: 17.8%;
	}
	@media (max-width: ${breakpoint.default.to}px) {
		flex-direction: column;
		h4 {
			margin-right: 0;
			margin-bottom: 40px;
			text-align: left;
			width: 100%;
		}
		.partners__image {
			max-width: 226px;
	}
`

const PartnersWrapper = styled.div`
	display: flex;
	flex-grow: 1;
	justify-content: space-between;
	.img_wrapper {
		display: flex;
		flex-flow: column;
		justify-content: center;
		align-items: center;
		font-size: 13px;
		line-height: 165%;
		max-width: 250px;
		padding: 0 15px;
		position: relative;
		a {
			color: white;
			text-decoration: none;
		}
		a:hover {
			text-decoration: underline;
		}
	}
	.logo_reference {
		background: #f8f9fc;
		border-radius: 20px;
		position: absolute;
		padding: 0 7px;
		left: 5px;
		top: 54px;
		a {
			color: #0b1237;
		}
	}
	@media (max-width: ${breakpoint.default.to}px) {
		flex-direction: column;
		align-items: center;
		.partners__image {
			margin-bottom: 60px;
		}
		.logo_reference {
			bottom: 35px;
		}
		.img_wrapper:last-child {
			.partners__image {
				margin-bottom: 0;
			}
		}
	}
`

export { StyledPartners, PartnersWrapper }
